/*!

 =========================================================
 * Material Dashboard React - v1.10.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark;
}

.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  /* background-color: #eeeeee; */
  color: #fff;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: '\2014 \00A0';
}

small {
  font-size: 80%;
}
p {
  font-size: 1.2em;
  color: #ffffff;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
  color: black !important;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background: url(../img/bg-theme.png) #131313;
  color: #ffffff;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  /* color: black !important; */
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  visibility: hidden;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}
* {
  letter-spacing: normal !important;
}
.socialIconsUl {
  padding-left: 3px;
  display: flex;
  margin-top: -26px;
}

.socialIconsLI {
  list-style: none;
}
.socialIconsA {
  color: #65b3ae !important;
  border-radius: 6px;
  background: #000;
  /* border: 1px solid #000; */
  padding: 16px 5px 0px 5px;
  margin-right: 3px;
}
.socialIconsA a {
  color: #65b3ae !important;

  background: #000;
}
a:hover {
  /* color: #000 !important;

  background: #65B3AE; */
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.MuiPaper-root{
  /* background: none !important; */
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.PrivateTabIndicator-colorSecondary-91 {
  background-color: #65b3ae;
}
hr.solid {
  border-top: 1px solid #000;
  width: 100%;
}
.toggle {
  font-size: 20px;
  height: 40px;
}
.input1 {
  text-indent: 35px;
}
.form .postBtn {
  width: 200px !important;
  background-color: #000 !important;
  border: none;
  color: white;
  height: 38px;
  font-size: 14px;
  border-radius: 5px;
  text-transform: capitalize;
}
.form .postBtn:hover {
  border: 2px solid rgb(0 240 52);
  background-color: rgb(0, 184, 240);
}
.overlay {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: 'Noto Sans', sans-serif;
  width: 1250px;
}
.page-header .title-page {
  color: #fff;
}
.sidebarWrapper-custom{
  background: #000000;
}
.drawerPaper-custom .logo-container{
  background: #000000;
  padding-left: 10px;
  padding-right: 10px;
}
.drawerPaper-custom .logo-container .makeStyles-logoLink-9{
  text-align: center;
}
.drawerPaper-custom .logo-container .img-logo{
  position: relative;
  width: auto;
  top: unset;
  right: 0;
  max-width: 100%;
}
.dashboard-content h1{
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 20px;
}
.dashboard-content h3{
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #3AB253;
}
.dashboard-content p{
  font-size: 17px;
}
.dashboard-content p.p-fist{
  margin-top: 50px;
}
.main-panel .page-header{
  top: 50px;
  color: #ffffff;
}
.main-panel .panel-content{
  margin-top: 130px;
  padding-left: 45px;
}
.nav-item-icon{
  float: left;
    width: 16px;
    height: 16px;
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    margin-right: 15px;
    vertical-align: middle;
    margin-top: 7px;
}
.sidebarWrapper-custom .MuiListItemText-root{
  color: #919692;
}
.sidebarWrapper-custom .active .MuiListItemText-root{
  color: #fff;
}
.sidebarWrapper-custom .active .MuiListItem-button {
  box-shadow: none;
  background-color: #1D8232;
}
.sidebarWrapper-custom .MuiListItem-button svg path {
  fill: #ffffff;
}
.sidebarWrapper-custom .MuiListItem-button:hover {
  box-shadow: none;
  background-color: #145e24;
}
.sidebarWrapper-custom .btn-wallet {
  background: #1D8232;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  box-shadow: none;
}
.page-header .MuiButton-contained {
  box-shadow: none;
  background-color: #1D8232;
  color: #fff;;
}
.page-header .MuiButton-contained:hover {
  box-shadow: none;
  background-color: #145e24;
  color: #fff;;
}
.PrivateHiddenCss-smDown .drawerPaper-custom{
  box-shadow: none;
}
.create-token-container {
  gap: 20px;
  margin: 0 !important;
}
.create-token-container .create-token-left{
/*  background: #071545;*/
  padding: 40px !important;
  border-radius: 0px;
}

.create-token-container .create-token-right{
/*  background: #071545;*/
  padding: 40px !important;
  border-radius: 0px;
}
.create-token-container .card-token-factory {
  background: none;
  color: #fff;
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0;
}
.create-token-container .card-token-factory-body{
  padding: 0;
}
.create-token-container .text-white{
  color: #fff;
}
.card-token-factory-body .list-fees{
  list-style: none;
  padding: 0;
  margin: 0;
}
.list-fees .list-fees-item{
  border-bottom: 2px solid #4F4F4F;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.list-fees .list-fees-item:last-child{
  border-bottom: none;
}
.list-fees .list-fees-item >div:first-child{
  color: #FFFFFF;
}
.create-token-left .MuiInputBase-input{
  color: #fff;
}
.create-token-left .MuiOutlinedInput-notchedOutline{
  border-color: #4F4F4F;
}
.create-token-left .MuiInputBase-formControl {
  color: #fff;
}
.create-token-left label{
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
.create-token-left .MuiInputBase-formControl:before{
  border-bottom: 1px solid #4F4F4F;
}
.create-token-left .css-10ku4dh-MuiButtonBase-root-MuiCheckbox-root,
.create-token-left .css-1y35j9v-MuiButtonBase-root-MuiCheckbox-root{
  color: #fff;
}
.create-token-left .MuiSwitch-track{
  background-color: #23305C;
}
.create-token-left .MuiDivider-fullWidth{
  border-color: #4F4F4F;
}
.create-token-left .MuiStack-root{
  padding: 5px 15px;
}
.create-token-left .btn-create-token{
  width: 100%;
  color: #fff;
  background: #1D8232;
  border-color: #1D8232;
  padding-top: 10px;
  padding-bottom: 10px;
}
.create-token-lefttop{
  margin-bottom: 30px;
}
.create-token-lefttop .btn-load-token{
  background-color: #1D8232;
  color: #fff;
  text-transform: none;
  border-color: #1D8232;
}
.card-dark {
  background: none !important;
  padding: 40px;
  border-radius: 0px !important;
  color: #ffffff !important;
  width: auto !important;
  box-shadow: none !important;
}
.card-dark .card-dark-body {
  padding: 0 !important;
}
.card-dark .input-dark input {
  color: #ffffff;
  padding: 15px 25px;
}
.card-dark .input-dark input::placeholder {
  color: #4B5371 !important;
}
.card-dark .input-dark label {
  color: #4B5371;
  padding: 25px;
  top: -12px;
}
.card-dark .input-dark label.MuiInputLabel-shrink {
  color: #ffffff !important;
  left: -46px;
  transform: translate(0, -14.5px) scale(1);
}
.card-dark .icon {
  margin-bottom: 16px;
}
.card-dark .input-dark .MuiInputBase-formControl:not(.Mui-focused):before {
  border-bottom-color: transparent;
}
.card-dark .box-balance {
  border-left: 6px solid #1D8232;
  padding-left: 20px;
}
.card-dark .box-balance p {
  color: #868DA6;
}
.card-dark .box-balance h3 {
  color: #ffffff;
}
.mt-58 {
  margin-top: 58px !important;
}
.card-dark .container-amount .MuiInputBase-formControl .MuiInputBase-input {
  color: #ffffff;
  padding: 15px 0;
}
.card-dark .container-amount .MuiInputBase-formControl .MuiInputBase-input.Mui-disabled {
  color: #4B5371;
  -webkit-text-fill-color: #4B5371;
  padding: 15px 0;
}
.card-dark .container-amount .MuiInputLabel-formControl {
  font-size: 16px !important;
  color: #4B5371 !important;
  top: 7px;
}
.card-dark .container-amount .MuiInputBase-formControl::before {
  border-color: #4F4F4F !important;
}
.card-dark .container-amount .MuiInputLabel-shrink {
  font-size: 16px !important;
  color: #ffffff !important;
  transform: translate(0, -11.5px) scale(1);
}
.card-dark .container-amount .MuiFormControl-fullWidth {
  margin-top: 0 !important;
}
.card-dark .token-info {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #4F4F4F;
  color: #ffffff;
  padding-bottom: 20px;
  font-size: 16px;
  padding-top: 20px;
}
.card-dark .token-info:first-child {
  padding-top: 0;
}
.card-dark .token-info:last-child {
  border-bottom: none;
}
.card-dark .token-info .title {
  color: #FFFFFF;
}
.container-filter {
  margin-top: 30px;
}
.container-filter .btn-search {
  background: transparent;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 0;
  left: 0;
}
.container-filter .btn-search:hover {
  background: transparent;
  border: none;
  box-shadow: none;
}
.container-filter .MuiInputBase-formControl::before {
  border-bottom: 2px solid #4F4F4F;
}
.container-filter .MuiInputBase-formControl .MuiInputBase-input {
  color: #ffffff;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 50px;
}
.mt-50 {
  margin-top: 50px;
}
@media (max-width: 960px) {
  .main-panel .panel-content {
    padding-left: 15px;
  }
  .page-header .title-page {
    padding-left: 0;
  }
  .drawerPaper-custom {
    box-shadow: none !important;
  }
}